import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentTextField.figmaUrl.ios} codeUrl={checklists.componentTextField.codeUrl.ios} checklists={checklists.componentTextField.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Textfield are used to defines a text input control.`}</p>
    <p>{`Use Legion Textfield styles allow users to type text into an app, with support lots type and attribute to customize.`}</p>
    <h3>{`Usage With Theme`}</h3>
    <p><strong parentName="p">{`Available themes: ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy`}</strong></p>
    <p><strong parentName="p">{` Outline `}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNOutlineTextField(
    text: $text
  )
`}</code></pre>
    <p><strong parentName="p">{` Inline `}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNInlineTextField(
    text: $text
  )
`}</code></pre>
    <h3>{`Usage Without Theme`}</h3>
    <p><strong parentName="p">{` Outline `}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
OutlineTxtField(
    text: $text
  )
`}</code></pre>
    <p><strong parentName="p">{` Inline `}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
InlineTxtField(
    text: $text
  )
`}</code></pre>
    <div className="divi" />
    <h3>{`Outline`}</h3>
    <h3>{`Outline State`}</h3>
    <h4>{`Idle`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-outline-idle.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNOutlineTextField(
    titleKey: "Placeholder"
    text: $text
  )
  .state(.idle)
`}</code></pre>
    <h4>{`Error`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-outline-error.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNOutlineTextField(
    titleKey: "Placeholder"
    text: $text
  )
  .state(.error)
`}</code></pre>
    <h4>{`Success`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-outline-success.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNOutlineTextField(
    titleKey: "Placeholder"
    text: $text
  )
  .state(.success)
`}</code></pre>
    <h4>{`Disabled`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-outline-disabled.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNOutlineTextField(
    titleKey: "Placeholder"
    text: $text
  )
  .state(.disable)
`}</code></pre>
    <h3>{`Outline Left Icon`}</h3>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-outline-left-icon.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNOutlineTextField(
    titleKey: "Placeholder"
    text: $text,
    leftIcon: Image(systemName: "info.circle")
  )
`}</code></pre>
    <h3>{`Outline Secure Text`}</h3>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-outline-secure-text.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
@State var isSecured: Bool = true
LGNOutlineTextField(
    titleKey: "Placeholder", 
    text: $text
  )
  .setSecured($isSecured)
`}</code></pre>
    <h3>{`Outline Label Title`}</h3>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-outline-label-title.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNOutlineTextField(
    titleKey: "Placeholder", 
    text: $text,
    label: "title"
  )
`}</code></pre>
    <h3>{`Outline Caption`}</h3>
    <h4>{`Caption with Image`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-outline-caption-with-image.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNOutlineTextField(
    titleKey: "Placeholder", 
    text: $text
  )
  .showCaption(
    caption: ContentModel(
      image: Image(systemName: "exclamationmark.triangle"),
      text: "caption"
    ),
    showCaption: true
  )
`}</code></pre>
    <h4>{`Caption without Image`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-outline-caption.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNOutlineTextField(
    titleKey: "Placeholder", 
    text: $text
  )
  .showCaption(
    caption: ContentModel(text: "caption"),
    showCaption: true
  )
`}</code></pre>
    <h3>{`Outline Prefix`}</h3>
    <h4>{`Prefix with Image`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-outline-prefix-with-image.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNOutlineTextField(
    titleKey: "Placeholder", 
    text: $text,
    prefix: ContentModel(
      image: Image(systemName: "chevron.right"),
      text: "prefix"
    )
  )
`}</code></pre>
    <h4>{`Prefix without Image`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-outline-prefix.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNOutlineTextField(
    titleKey: "Placeholder", 
    text: $text,
    prefix: ContentModel(text: "prefix")
  )
`}</code></pre>
    <h3>{`Outline Suffix`}</h3>
    <h4>{`Suffix with Image`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-outline-suffix-with-image.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNOutlineTextField(
    titleKey: "Placeholder", 
    text: $text,
    suffix: ContentModel(
      image: Image(systemName: "chevron.right"),
      text: "suffix"
    )
  )
`}</code></pre>
    <h4>{`Suffix without Image`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-outline-suffix.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNOutlineTextField(
    titleKey: "Placeholder", 
    text: $text,
    suffix: ContentModel(text: "suffix")
  )
`}</code></pre>
    <h3>{`Outline Hint Text`}</h3>
    <h4>{`Hint and Caption`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-outline-hint-caption.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNOutlineTextField(
    titleKey: "Placeholder", 
    text: $text
  )
  .showCaption(
    caption: ContentModel(
      text: "Caption",
      hintText: "Hint Text"
    ),
    showCaption: true,
    showHint: true
  )
`}</code></pre>
    <h4>{`Hint only`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-outline-hint.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNOutlineTextField(
    titleKey: "Placeholder", 
    text: $text
  )
  .showCaption(
    caption: ContentModel(
      text: "Caption",
      hintText: "Hint Text"
    ),
    showCaption: false,
    showHint: true
  )
`}</code></pre>
    <div className="divi" />
    <h3>{`Inline`}</h3>
    <h3>{`Inline State`}</h3>
    <h4>{`Idle`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-inline-idle.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNInlineTextField(
    titleKey: "Placeholder"
    text: $text
  )
  .state(.idle)
`}</code></pre>
    <h4>{`Error`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-inline-error.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNInlineTextField(
    titleKey: "Placeholder"
    text: $text
  )
`}</code></pre>
    <h4>{`Success`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-inline-success.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNInlineTextField(
    titleKey: "Placeholder"
    text: $text
  )
  .state(.success)
`}</code></pre>
    <h4>{`Disabled`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-inline-disabled.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNInlineTextField(
    titleKey: "Placeholder"
    text: $text
  )
  .state(.disable)
`}</code></pre>
    <h3>{`Inline Left Icon`}</h3>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-inline-left-icon.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNInlineTextField(
    titleKey: "Placeholder"
    text: $text,
    leftIcon: Image(systemName: "info.circle")
  )
`}</code></pre>
    <h3>{`Inline Secure Text`}</h3>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-inline-secure-text.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
@State var isSecured: Bool = true
LGNInlineTextField(
    titleKey: "Placeholder", 
    text: $text
  )
  .setSecured($isSecured)
`}</code></pre>
    <h3>{`Inline Label Title`}</h3>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-inline-label-title.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNInlineTextField(
    titleKey: "Placeholder", 
    text: $text,
    label: "title"
  )
`}</code></pre>
    <h3>{`Inline Caption`}</h3>
    <h4>{`Caption with Image`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-inline-caption-with-image.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNInlineTextField(
    titleKey: "Placeholder", 
    text: $text
  )
  .showCaption(
    caption: ContentModel(
      image: Image(systemName: "exclamationmark.triangle"),
      text: "caption"
    ),
    showCaption: true
  )
`}</code></pre>
    <h4>{`Caption without Image`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-inline-caption.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNInlineTextField(
    titleKey: "Placeholder", 
    text: $text
  )
  .showCaption(
    caption: ContentModel(text: "caption"),
    showCaption: true
  )
`}</code></pre>
    <h3>{`Inline Prefix`}</h3>
    <h4>{`Prefix with Image`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-inline-prefix-with-image.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNInlineTextField(
    titleKey: "Placeholder", 
    text: $text,
    prefix: ContentModel(
      image: Image(systemName: "chevron.right"),
      text: "prefix"
    )
  )
`}</code></pre>
    <h4>{`Prefix without Image`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-inline-prefix.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNInlineTextField(
    titleKey: "Placeholder", 
    text: $text,
    prefix: ContentModel(text: "prefix")
  )
`}</code></pre>
    <h3>{`Inline Suffix`}</h3>
    <h4>{`Suffix with Image`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-inline-suffix-with-image.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNInlineTextField(
    titleKey: "Placeholder", 
    text: $text,
    suffix: ContentModel(
      image: Image(systemName: "chevron.right"),
      text: "suffix"
    )
  )
`}</code></pre>
    <h4>{`Suffix without Image`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-inline-suffix.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNInlineTextField(
    titleKey: "Placeholder", 
    text: $text,
    suffix: ContentModel(text: "suffix")
  )
`}</code></pre>
    <h3>{`Inline Hint Text`}</h3>
    <h4>{`Hint and Caption`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-inline-hint-caption.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNInlineTextField(
    titleKey: "Placeholder", 
    text: $text
  )
  .showCaption(
    caption: ContentModel(
      text: "Caption",
      hintText: "Hint Text"
    ),
    showCaption: true,
    showHint: true
  )
`}</code></pre>
    <h4>{`Hint only`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/ios-textfield-inline-hint.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`@State var text: String = ""
LGNInlineTextField(
    titleKey: "Placeholder", 
    text: $text
  )
  .showCaption(
    caption: ContentModel(
      text: "Caption",
      hintText: "Hint Text"
    ),
    showCaption: false,
    showHint: true
  )
`}</code></pre>
    <h2>{`Properties`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Properties`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`titleKey`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The key for the localized title of the text field, describing its purpose, default value is empty string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`empty string `}<inlineCode parentName="td">{`""`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The text to display and edit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`not have`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The label to represent the title of the text field, default value is nil`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`nil (literal null value for objective-C classes)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`leftIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The icon is on the left of the text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`nil (literal null value for objective-C classes)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`prefix`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To show prefix content with position left side in text field`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`nil (literal null value for objective-C classes)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sufix`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To show suffix content with position right side in text field`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`nil (literal null value for objective-C classes)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`defaultBorderColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color will appear on the border of the text field, default value is Color tertiary300`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.tertiary300`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`errorBorderColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color will appear on the border of the text field on the error state, default value is Color error500`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.error500`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`successBorderColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color will appear on the border of the text field on the success state, default value is Color success500`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.success500`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      